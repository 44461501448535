import { useState } from 'react';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { REST_ENDPOINTS } from 'commons/constants';

export const useDeleteAccount = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteAccount = async (userId: string): Promise<boolean> => {
    setIsDeleting(true);
    try {
      const response = await leagueFetch(`${REST_ENDPOINTS.USER}/${userId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setIsDeleting(false);
        return true;
      }

      setError(`Account deletion failed: ${response.status}`);
      setIsDeleting(false);
      return false;
    } catch (err: any) {
      setError(err.message || 'An error occurred during account deletion.');
      setIsDeleting(false);
      return false;
    }
  };

  return {
    isDeleting,
    error,
    deleteAccount,
  };
};
