import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  Icon,
  Modal,
  Divider,
  Box,
} from '@leagueplatform/genesis-core';
import { useLocation, NavLink } from '@leagueplatform/routing';
import {
  sendCapabilityNavLinkEvent,
  sendUserOptionEvent,
} from 'utils/analytics-events';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';
import {
  useNavLinksList,
  NavLink as NavLinkInterface,
} from '../use-nav-links-list.hook';
import {
  defaultMobileNavLinkStyles,
  MobileNavLink,
} from './mobile-nav.links.component';

type MobileNavProps = {
  closeModal: () => void;
  handleDeleteAccount: () => void;
};

export const MobileNav = ({
  closeModal,
  handleDeleteAccount,
}: MobileNavProps) => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const location = useLocation();
  const handleCapabilityNavLinkClick = (action: string) => {
    closeModal();
    sendCapabilityNavLinkEvent(action);
  };
  const mainNavLinks = navLinks.map(
    ({ message_id, icon, to }: NavLinkInterface) => {
      const isActiveLink = location.pathname.startsWith(to as string);
      return (
        <Box
          as={NavLink}
          to={to as string}
          id={`${formatMessage({ id: message_id })}-link`}
          key={`${formatMessage({ id: message_id })}-link`}
          css={defaultMobileNavLinkStyles}
          onClick={() => handleCapabilityNavLinkClick(message_id)}
        >
          {icon && (
            <Icon
              icon={isActiveLink ? `${icon}Filled` : icon}
              css={{ marginRight: '$half' }}
            />
          )}
          {formatMessage({ id: message_id })}
          <Icon
            icon="interfaceChevronRight"
            size="$one"
            tint="$onSurfaceTextSubdued"
            css={{ marginLeft: 'auto' }}
          />
        </Box>
      );
    },
  );

  const userDropdownItems = useUserDropdownConfig(handleDeleteAccount);

  return (
    <StackLayout as="nav" spacing="$one" horizontalAlignment="stretch">
      {/* @ts-ignore */}
      <Modal.Title>
        <HeadingText
          level="2"
          size="sm"
          css={{
            paddingLeft: '$half',
          }}
        >
          {formatMessage({ id: 'STR_MENU' })}
        </HeadingText>
      </Modal.Title>
      <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
        {mainNavLinks}
      </StackLayout>
      {userDropdownItems?.map(({ heading, items }) => (
        <StackLayout
          spacing="$oneAndHalf"
          css={{
            width: '100%',
          }}
          key={`mobile_nav--${heading || items[0]?.text}`}
          horizontalAlignment="stretch"
        >
          <Divider css={{ margin: '0 $half', width: 'calc(100% - $half)' }} />
          <StackLayout spacing="$half" horizontalAlignment="stretch">
            {heading && (
              <HeadingText
                level="3"
                size="md"
                css={{
                  paddingLeft: '$half',
                  fontSize: '',
                }}
              >
                {formatMessage({ id: heading as any })}
              </HeadingText>
            )}
            <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
              {items?.map((item) => {
                const { text } = item;
                const handleOptionClick = () => {
                  closeModal();
                  sendUserOptionEvent(text);
                  if ('onClick' in item) item.onClick();
                };

                return (
                  <MobileNavLink
                    linkData={item}
                    onLinkClick={handleOptionClick}
                    key={`mobile-nav-link--${text}`}
                  >
                    {item?.icon && (
                      <Icon
                        icon={item.icon}
                        size="$oneAndHalf"
                        css={{ marginRight: '$half' }}
                      />
                    )}
                    {formatMessage({ id: text as any })}

                    {item?.linkIcon && (
                      <Icon
                        icon={item.linkIcon}
                        size="$one"
                        tint="$onSurfaceTextSubdued"
                        css={{ marginLeft: 'auto' }}
                      />
                    )}
                  </MobileNavLink>
                );
              })}
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ))}
    </StackLayout>
  );
};
