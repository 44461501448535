import { Box, HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import {
  BackButtonArrow,
  PageHeaderBackground,
} from '@leagueplatform/web-common-components';

interface Props {
  title: string;
  height?: string;
  children?: React.ReactNode;
  goBack?: () => void;
}

export const HeroSection = ({
  title,
  height = 'auto',
  goBack,
  children,
}: Props) => (
  <PageHeaderBackground height={height} pageHeaderBackgroundHeight="212px">
    <Box css={{ width: '100%' }}>
      <StackLayout
        orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
        css={{ alignItems: 'baseline' }}
      >
        {goBack && (
          <BackButtonArrow
            fontSize="heading3"
            color="onSurface.text.primary"
            marginRight="one"
            marginY="half"
            justifyContent="initial"
            onClick={goBack}
          />
        )}
        <HeadingText
          level="1"
          size={{ '@initial': 'xxl', '@mobile': 'lg' }}
          css={{ marginBlock: '$one $one' }}
        >
          {title}
        </HeadingText>
      </StackLayout>
      {children}
    </Box>
  </PageHeaderBackground>
);
