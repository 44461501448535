import { GDSIconProps, GDSStyleObject } from '@leagueplatform/genesis-core';

export interface FooterLinkProps {
  text: string;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

const boldTextLink: GDSStyleObject = {
  fontWeight: 'bold',
};

export const footerLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'STR_TERMS_OF_SERVICE',
    url: 'www.scfhp.com/terms-and-conditions/',
    target: '_blank',
    linkProps: boldTextLink,
  },
  {
    text: 'STR_PRIVACY_PRACTICES',
    url: '',
    target: '_blank',
    linkProps: boldTextLink,
  },
  {
    text: 'STR_NON_DESCRIMINATORY_NOTICE',
    url: '',
    target: '_blank',
    linkProps: boldTextLink,
  },
];

export const footerSubLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'STR_SCFHP_WEBSITE',
    url: '',
    target: '_blank',
    icon: '',
  },
  {
    text: 'STR_FACEBOOK',
    url: '',
    target: '_blank',
    icon: '',
  },
  {
    text: 'STR_INSTAGRAM',
    url: '',
    target: '_blank',
    icon: '',
  },
  {
    text: 'STR_CALENDER_OF_EVENTS',
    url: '',
    target: '_blank',
    icon: '',
  },
];
