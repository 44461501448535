import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  GDSStyleObject,
  ParagraphText,
  TextAction,
} from '@leagueplatform/genesis-core';
import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';

interface CaptionWithLinkProps {
  messageId: string;
  linkHref: string;
  linkTextId: string;
  id?: string;
  customStyles?: GDSStyleObject;
  linkFontSize?: string;
  isNewLine?: boolean;
}

export const CaptionWithLink: React.FC<CaptionWithLinkProps> = ({
  messageId,
  linkHref,
  linkTextId,
  id,
  customStyles,
  linkFontSize,
  isNewLine = false,
}) => {
  const { formatMessage } = useIntl();

  const linkStyles: React.CSSProperties = {
    fontSize: linkFontSize ?? 'inherit',
    lineHeight: 'inherit',
    textDecoration: 'underline',
    marginLeft: isNewLine ? '0' : '4px',
    display: isNewLine ? 'block' : 'inline',
  };

  return (
    <ParagraphText size="xs" id={id} css={customStyles}>
      {formatMessage({ id: messageId as never })}
      <span style={linkStyles}>
        <TextAction
          as="a"
          href={linkHref}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formatMessage({ id: linkTextId as never })}
          <OpensInANewTabMessage />
        </TextAction>
      </span>
    </ParagraphText>
  );
};
