import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useCallback } from 'react';

export const useHandleAccountDeleted = () => {
  const handleAccountDeleted = useCallback(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  }, []);

  return {
    handleAccountDeleted,
  };
};
