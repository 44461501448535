import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { GDSIconProps } from '@leagueplatform/genesis-core';
import type { LocalesKey } from '../../types/types';

export const NAV_IDS: { [key: string]: LocalesKey } = {
  HOME: 'STR_HOME',
  BENEFITS: 'STR_BENEFITS',
  CARE: 'STR_GET_CARE',
  JOURNEY: 'STR_JOURNEY',
  DASHBOARD: 'STR_DASHBOARD',
};

type Keys = keyof typeof NAV_IDS;
type NavIds = (typeof NAV_IDS)[Keys];

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
  icon?: GDSIconProps['icon'];
}

export const useNavLinksList = () => {
  const navLinksList: NavLink[] = [
    {
      to: '/home',
      message_id: NAV_IDS.HOME,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NAV_IDS.BENEFITS,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NAV_IDS.CARE,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NAV_IDS.JOURNEY,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.aboutMe),
      message_id: NAV_IDS.DASHBOARD,
      type: LinkType.NAV,
    },
  ];

  return {
    navLinks: navLinksList,
  };
};
