import { UserDropdownComponent } from '@leagueplatform/web-common-components';
import {
  SCREEN_NAMES,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { Box } from '@leagueplatform/genesis-core';
import { getUserOptionAnalyticsProps } from 'utils/analytics-events';
import { useUserDropdownComponent } from '../../../hooks/user-dropdown.hook';

interface Props {
  handleDeleteAccount: () => void;
}

const userBarAnalytics = {
  name: EVENT_NAME.BUTTON_CLICKED,
  props: {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.HOME,
    detail: 'open settings menu',
  },
};

export const UserDropdown = ({ handleDeleteAccount }: Props) => {
  const { data } = useUserDropdownComponent(handleDeleteAccount);

  return (
    <Box
      css={{
        margin: 'auto 0',
        '#user_dropdown_menu button': {
          fontWeight: '700',
          '&:focus::before': {
            outline: 'none',
            boxShadow: 'none',
          },
        },
      }}
    >
      <UserDropdownComponent
        label={data.preferredFirstName.toUpperCase()}
        dropdownItemsConfig={data.menuItemsConfig.map(
          (section) => section.items,
        )}
        userBarAnalyticsProps={userBarAnalytics}
        getDropdownItemAnalyticsProps={getUserOptionAnalyticsProps}
      />
    </Box>
  );
};
