import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/genesis-core';
import { trackAnalyticsEvent } from '@leagueplatform/analytics';
import { usePersonalInformation } from 'hooks/use-personal-information.hook';
import { useElementHeight } from 'hooks/use-element-height.hook';
import { FormContent } from 'components/post-authentication/account-setup/form-content.component';
import { EditProfilePhoto } from 'components/forms/edit-profile-photo/edit-profile-photo.component';
import { FixedFormFooter } from 'components/forms/fixed-form-footer.component';
import { AccountSetupTopSection } from 'components/post-authentication/account-setup/top-section.component';
import { AccountSetupFormValues } from 'components/post-authentication/account-setup/account-setup.types';
import { OnboardingStage } from 'commons/types';

export const AccountSetupForm = ({
  onStageChange,
}: {
  onStageChange: (stage: OnboardingStage) => void;
}) => {
  const { formatMessage } = useIntl();
  const { data } = usePersonalInformation();

  useEffect(() => {
    trackAnalyticsEvent('SCREEN_LOADED', {
      product_area: 'ONBOARDING',
      sub_product_area: 'ACCOUNT_SET_UP',
      screen_name: 'ACCOUNT_SET_UP',
    });
  }, []);

  const onSubmit = (values: AccountSetupFormValues) => {
    if (values.isTermsAccepted) {
      trackAnalyticsEvent('BUTTON_CLICKED', {
        product_area: 'ONBOARDING',
        sub_product_area: 'ACCOUNT_SET_UP',
        screen_name: 'ACCOUNT_SET_UP',
        detail: 'continue',
      });
      onStageChange('allSet');
    }
  };
  const { elementRef: footerRef, elementHeight: footerHeight } =
    useElementHeight();

  const initialValues: AccountSetupFormValues = {
    preferredFirstName: data?.userProfile?.preferredFirstName ?? '',
    isTermsAccepted: false,
    isEmailEnabled: !!data?.userProfile?.optedIntoMarketingCommunications,
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={() => ({})}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <Form>
          <StackLayout
            horizontalAlignment="center"
            css={{
              maxWidth: 768,
              padding: '$two',
              margin: '$none auto',
              marginBottom: footerHeight,
              '@mobile': { padding: '$oneAndHalf' },
            }}
          >
            <AccountSetupTopSection
              title={formatMessage({ id: 'STR_ACCOUNT_SETUP_TITLE' })}
              description={formatMessage({
                id: 'STR_ACCOUNT_SETUP_DESCRIPTION',
              })}
            />
            <EditProfilePhoto
              fileInputOnClickCallback={(profilePhotoId) => {
                trackAnalyticsEvent('BUTTON_CLICKED', {
                  product_area: 'ONBOARDING',
                  screen_name: 'ACCOUNT_SET_UP',
                  detail: `${profilePhotoId ? 'change' : 'add'} profile photo`,
                });
              }}
            />
            <StackLayout
              spacing="$one"
              horizontalAlignment="stretch"
              css={{ marginTop: '$oneAndHalf' }}
            >
              <FormContent
                setFieldValue={formProps.setFieldValue}
                values={formProps.values}
                email={data.email}
              />
            </StackLayout>
          </StackLayout>
          <FixedFormFooter
            dirty={formProps?.dirty}
            back={() => onStageChange('featureHighlights')}
            next={() => onSubmit(formProps.values)}
            allowSubmit={!!formProps?.values?.isTermsAccepted}
            isLoading={formProps.isSubmitting}
            submitText={formatMessage({ id: 'STR_CONTINUE' })}
            ref={footerRef}
          />
        </Form>
      )}
    </Formik>
  );
};
