import { Box } from '@leagueplatform/genesis-core';
import OnboardingRoutesWithProvider from '@leagueplatform/onboarding';

export const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    role="dialog"
    css={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '100',
      overflow: 'auto',
      '> div > div > img': {
        height: '100vh',
        objectFit: 'cover',
      },
    }}
  >
    {children}
  </Box>
);

export const OnboardingFeatureHighlights = ({
  onOnboardingComplete,
}: {
  onOnboardingComplete: (error?: Error) => void;
}) => (
  <Container>
    <OnboardingRoutesWithProvider
      contentfulId={import.meta.env.VITE_CONTENTFUL_ID}
      tenantId={import.meta.env.VITE_TENANT_ID}
      onOnboardingComplete={onOnboardingComplete}
    />
  </Container>
);
