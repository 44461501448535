import * as React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';

interface FlexibleCardLayoutProps {
  children: React.ReactNode;
}

const FlexibleCardLayout: React.FC<FlexibleCardLayoutProps> = ({
  children,
}) => (
  <StackLayout
    css={{
      backgroundColor: '$surfaceBackgroundPrimary',
      gap: '$half',
      padding: '$oneAndHalf',
      border: 'thin solid $onSurfaceBorderSubdued',
      borderRadius: '$medium',
      maxWidth: 816,
      marginInlineStart: '$none',
      '@desktop': { marginInlineStart: '$three' },
    }}
  >
    {children}
  </StackLayout>
);

export default FlexibleCardLayout;
