import { useIntl } from '@leagueplatform/locales';
import Masonry from '@leagueplatform/masonry';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';

const config = {
  appId: 'scfhp_contact_us',
  apiVersion: 2,
  analytics: {
    productArea: 'scfhp-app',
    screenName: 'masonry contact-us-screen',
  },
};

const ContactUsPageMasonry = Masonry.init(config);

export const ContactUsPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_CONTACT_US' }));

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: 'Contact Us',
  });

  return (
    <MainPageContainer width="100%">
      <HeroSection title={formatMessage({ id: 'STR_CONTACT_US' })} />
      <StackLayout
        css={{
          paddingTop: '134px',
          maxWidth: '894px',
        }}
      >
        <ContactUsPageMasonry.Provider />
      </StackLayout>
    </MainPageContainer>
  );
};
