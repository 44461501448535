import { UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const TermAndConditionsText = ({
  showIhaveRead,
  style,
}: {
  showIhaveRead?: boolean;
  style?: any;
}) => {
  const { formatMessage } = useIntl();

  return (
    <UtilityText css={{ fontSize: '16px', ...style }}>
      {showIhaveRead && `${formatMessage({ id: 'STR_I_HAVE_READ' })} `}
      <a
        href="https://www.scfhp.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        {formatMessage({ id: 'STR_PRIVACY_POLICY' })}
      </a>
      {` ${formatMessage({ id: 'STR_AND' })} `}
      <a
        href="www.scfhp.com/terms-and-conditions/"
        target="_blank"
        rel="noreferrer"
      >
        {formatMessage({ id: 'STR_TERMS_OF_SERVICE' })}
      </a>
      .
    </UtilityText>
  );
};
