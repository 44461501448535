// Onboarding asset overrides
import onboardingFeatureHighlightBg from 'assets/feature-background.svg';
// import journeyHeaderBackground from 'assets/journey-header-background.svg';

// const JOURNEY_ASSETS = {
//   JOURNEY_HEADER_BACKGROUND_IMAGE: journeyHeaderBackground,
// };

const ONBOARDING_ASSETS = {
  FEATURE_HIGHLIGHT_BACKGROUND: onboardingFeatureHighlightBg,
};

export const ASSETS_CONFIG = {
  // ...JOURNEY_ASSETS,
  ...ONBOARDING_ASSETS,
};
