/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import {
  AppBarItem,
  AppBarNavLink,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import {
  Button,
  Modal,
  queryHelpers,
  TextAction,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { useGetUserProfile } from '@leagueplatform/web-common';

import { DeleteAccountModal } from 'components/modals/delete-account-modal.component';
import { AccountDeletedModal } from 'components/modals/account-deleted-modal.component';
import { useDeleteAccount } from 'hooks/useDeleteAccount';
import { useHandleAccountDeleted } from 'hooks/useHandleAccountDeleted';
import logoWithText from 'assets/logo-with-text.svg';
import { useModal } from 'hooks/use-modal.hook';
import { UserDropdown } from './user-dropdown/user-dropdown.component';
import { useNavLinksList } from './use-nav-links-list.hook';
import { MobileNav } from './mobile-nav/mobile-nav.component';

import {
  HeaderNavBar,
  HEADER_NAV_BAR_DEFAULT_HEIGHT,
} from './header-nav-bar.component';

export const APP_HEADER_NAV_DEFAULT_HEIGHT = HEADER_NAV_BAR_DEFAULT_HEIGHT;

export const AppNavBarComponent = React.memo(() => {
  const { formatMessage } = useIntl();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const { navLinks } = useNavLinksList();
  const { deleteAccount } = useDeleteAccount();
  const { handleAccountDeleted } = useHandleAccountDeleted();
  const { data: userProfile } = useGetUserProfile();

  const sendNavBarAnalyticsEvent = (action: string) => () =>
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: 'Navigation Bar',
      detail: action,
    });

  const {
    isShowing: showDeleteAccountModal,
    toggle: toggleDeleteAccountModal,
  } = useModal();

  const {
    isShowing: showAccountDeletedModal,
    toggle: toggleAccountDeletedModal,
  } = useModal();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  const handleDeleteAccountSubmit = async () => {
    const userId = userProfile?.userId;
    const success = await deleteAccount(userId as string);
    if (success) {
      toggleAccountDeletedModal();
    }
  };

  const logo = (
    <TextAction as={Link} to="/" onClick={sendNavBarAnalyticsEvent('HOME')}>
      <Image src={logoWithText} height="30px" alt="Santa clara logo" />
    </TextAction>
  );

  return (
    <HeaderNavBar>
      {!isMobile && (
        <>
          <AppBarItem margin="auto" paddingRight="97px">
            {logo}
          </AppBarItem>

          {/* App Nav Home & Capability Areas */}
          {navLinks.map((navLink) => (
            <AppBarNavLink
              to={navLink.to}
              key={navLink.message_id}
              onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
              color="onSurface.text.subdued"
              hoverStyle={{ color: 'onSurface.text.primary' }}
              minWidth="85px"
              justifyContent="center"
              fontWeight="bold"
            >
              {formatMessage({ id: navLink.message_id })}
            </AppBarNavLink>
          ))}

          {/* Spacer */}
          <AppBarItem aria-hidden flexGrow={20} />

          {/* App Nav User Dropdown */}
          <AppBarItem minWidth="150px" justifyContent="end">
            <UserDropdown handleDeleteAccount={toggleDeleteAccountModal} />
          </AppBarItem>
        </>
      )}
      {isMobile && (
        <Modal.Root
          open={menuOpen}
          onOpenChange={() => {
            setMenuOpen((previous) => !previous);
          }}
        >
          <AppBarItem margin="auto" paddingLeft="16px">
            {logo}
          </AppBarItem>
          {/* @ts-ignore */}
          <Modal.Trigger>
            <Button
              icon="interfaceMenu"
              hideLabel
              quiet
              priority="tertiary"
              size="large"
              css={{
                margin: '$half $quarter',
              }}
              aria-controls={undefined}
            >
              {formatMessage({ id: 'STR_OPEN_MENU' })}
            </Button>
          </Modal.Trigger>
          <Modal.Content
            layout="right"
            css={{
              '.GDS-modal': { maxWidth: 335 },
              '.GDS-modal-content': { paddingLeft: '$one' },
            }}
          >
            <MobileNav
              closeModal={() => setMenuOpen(false)}
              handleDeleteAccount={toggleDeleteAccountModal}
            />
          </Modal.Content>
        </Modal.Root>
      )}
      <Modal.Root
        open={showDeleteAccountModal}
        onOpenChange={toggleDeleteAccountModal}
      >
        <DeleteAccountModal onSubmit={handleDeleteAccountSubmit} />
      </Modal.Root>
      <Modal.Root
        open={showAccountDeletedModal}
        onOpenChange={() => {
          toggleAccountDeletedModal();
          handleAccountDeleted();
        }}
      >
        <AccountDeletedModal />
      </Modal.Root>
    </HeaderNavBar>
  );
});
