import {
  LEAGUE_MODULE_NAMES,
  Environment,
  LeagueConfig,
} from '@leagueplatform/core';
import { santaClaraTheme } from 'theme/SantaClaraLightTheme';
import { ASSETS_CONFIG } from 'commons/asset-config';
import EN_STRING_OVERRIDES from './locales/en.json';
import ES_STRING_OVERRIDES from './locales/es.json';
import TL_STRING_OVERRIDES from './locales/tl.json';
import ZH_STRING_OVERRIDES from './locales/zh.json';
import VI_STRING_OVERRIDES from './locales/vi.json';

export const routesConfig = {
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/health-need',
  [LEAGUE_MODULE_NAMES.onboarding]: '/onboarding',
  [LEAGUE_MODULE_NAMES.aboutMe]: '/member/dashboard',
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
};

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN!,
  client_id: VITE_AUTH0_CLIENT_ID!,
  redirect_uri: window.location.origin,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

const appConfig = {
  contentUrl: VITE_CONTENT_URL!,
  appEnvironment: VITE_APP_ENV as Environment,
};

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const config: LeagueConfig = {
  core: {
    tenantId: 'league',
    clientId: 'scfhp-web',
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    ...appConfig,
    customMethods: customMethodsConfig,
    routing: { isUsingLeagueRouting: true },
    ui: {
      theme: santaClaraTheme,
      components: {
        pageHeader: {
          shape: 'line',
        },
      },
      features: {
        pointsEnabled: true,
      },
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
        es: ES_STRING_OVERRIDES,
        zh: ZH_STRING_OVERRIDES,
        vi: VI_STRING_OVERRIDES,
        tlg: TL_STRING_OVERRIDES,
      },
    },
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
  careDiscovery: {
    masonryAppId: {
      left: 'scfhp_care_homepage_left',
      right: 'scfhp_care_homepage_right',
    },
  },
  careCollections: {
    showCareCollectionFeedbackComponent: true,
  },
  healthProfile: {
    ratingsBarEnabled: true,
  },
  aboutMe: {
    showAccountBanner: true,
  },
  wallet: {
    // enableNoNetworkLiveBalances: true,
    showLandingPageClaimsEntry: true,
    showLandingPageHeaderBranding: false,
  },
  claims: {
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: false,
    },
    showTotalCostOverviewAndAdditionalResources: false,
    filterableProperties: {
      patientName: {
        isVisible: false,
      },
    },
    showClaimStatusDefinitions: true,
  },
};
