import {
  StackLayout,
  Button,
  HeadingText,
  ParagraphText,
  Box,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { useHistory } from '@leagueplatform/routing';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import pageIcon from 'assets/you-are-all-set-badge.svg';
import { ACTIVITY_ID, CAMPAIGN_ID } from 'commons/constants';
import { useIntl } from '@leagueplatform/locales';

export const AllSetPage = ({ onCompleted }: { onCompleted: () => void }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const onClick = () => {
    onCompleted();
  };

  const goToActivity = () => {
    onCompleted();
    history.push({
      pathname: `/health-journey/campaign/${CAMPAIGN_ID}/activity/${ACTIVITY_ID}/toolbox`,
    });
  };

  return (
    <FullScreenContainer>
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          height: '100%',
          whiteSpace: 'pre-wrap',
          maxWidth: '351px',
          margin: '0 auto',
        }}
      >
        <Image
          src={pageIcon}
          alt="you are all set image"
          width="176px"
          height="auto"
          position="relative"
          top="minusOne"
          marginBottom="three"
        />
        <HeadingText
          level="3"
          size="xl"
          css={{
            marginBottom: '$half',
          }}
        >
          {formatMessage({ id: 'STR_YOU_ARE_ALL_SET_TITLE' })}
        </HeadingText>
        <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
          {formatMessage({ id: 'STR_YOU_ARE_ALL_SET_DESC' })}
        </ParagraphText>
        <Box
          css={{
            width: '265px',
            marginTop: '$oneAndHalf',
          }}
        >
          <Button
            width="fillContainer"
            css={{
              borderRadius: '24px',
            }}
            onClick={goToActivity}
          >
            {formatMessage({ id: 'STR_START_HEALTH_ASSESSMENT' })}
          </Button>
          <Button
            css={{
              marginTop: '$half',
              color: '$secondaryTextDefault',
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'inherit',
              },
            }}
            width="fillContainer"
            quiet
            size="medium"
            priority="tertiary"
            onClick={onClick}
          >
            {formatMessage({ id: 'STR_NOT_NOW' })}
          </Button>
        </Box>
      </StackLayout>
    </FullScreenContainer>
  );
};
