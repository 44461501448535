import { useState, useEffect } from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';

export const useOnboarding = () => {
  const { data, isLoading: isUserProfileLoading } = useGetUserProfile();
  const [stage, setStage] = useState('featureHighlights');
  const [isLoading, setLoading] = useState(true);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);

  useEffect(() => {
    if (!isUserProfileLoading) {
      const termsAccepted =
        !!data?.userProfile?.memberTermsAndConditions?.dateAccepted;
      const showFeatureHighlights =
        window.localStorage.getItem('showFeatureHighlights') !== 'false';

      if (termsAccepted && !showFeatureHighlights) {
        setStage('completed');
      } else if (showFeatureHighlights) {
        setStage('featureHighlights');
      } else {
        setStage('accountSetup');
      }

      setLoading(false);
    }
  }, [data, isUserProfileLoading]);

  const onFeatureHighlightComplete = () => {
    window.localStorage.setItem('showFeatureHighlights', 'false');
    setStage('accountSetup');
  };

  const onAccountSetupComplete = () => {
    setStage('allSet');
  };

  const onAllSetComplete = () => {
    setOnboardingCompleted(true);
    setStage('completed');
  };

  return {
    stage,
    isLoading,
    onboardingCompleted,
    setStage,
    onFeatureHighlightComplete,
    onAccountSetupComplete,
    onAllSetComplete,
  };
};
