import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import { StackLayout, ParagraphText } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';

export const AboutUsPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_ABOUT_US' }));
  const history = useHistory();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: 'About Us',
  });

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'STR_ABOUT_US' })}
        goBack={history.goBack}
      />
      <StackLayout
        css={{
          paddingTop: '134px',
          maxWidth: '750px',
        }}
      >
        <ParagraphText>
          {formatMessage({
            id: 'STR_ABOUT_US_CONTENT',
          })}
        </ParagraphText>
      </StackLayout>
    </MainPageContainer>
  );
};
