import { BoxProps } from '@leagueplatform/genesis-commons';
import { PATHS } from 'commons/constants';
import { Link } from 'commons/types';

export type Options = {
  heading?: string;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

// TODO: need to refactor: linksProps is used for web(as there is no color property available in UserDropDownComponent) and color props is used for mobile screen

export const useUserDropdownConfig = (
  handleDeleteAccount: () => void,
): Options[] => [
  {
    heading: 'STR_ACCOUNT_SETTINGS',
    items: [
      {
        text: 'STR_PERSONAL_INFO',
        to: '',
        color: '$onSurfaceTextSubdued',
        // icon: manageProfileIcon,
        linkIcon: 'interfaceChevronRight',
        linkProps: boldTextLink,
      },
      {
        text: 'STR_FORMS',
        to: PATHS.FORMS,
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'illustrativeDocument',
        linkIcon: 'interfaceChevronRight',
      },
      {
        text: 'STR_COMMUNICATION',
        to: PATHS.COMMUNICATION_PREFERENCES,
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'illustrativeDocument',
        linkIcon: 'interfaceChevronRight',
      },
      {
        text: 'STR_MANAGE_ACCOUNT',
        to: '/',
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'statusInformationCircle2',
        linkIcon: 'interfaceChevronRight',
      },
    ],
  },
  {
    heading: 'ABOUT',
    items: [
      {
        text: 'STR_ABOUT_SANTA_CLARA',
        to: PATHS.ABOUT,
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'statusInformationCircle2',
        linkIcon: 'interfaceChevronRight',
      },
      {
        text: 'STR_CONTACT',
        to: PATHS.CONTACT_US,
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'statusInformationCircle2',
        linkIcon: 'interfaceChevronRight',
      },

      {
        text: 'STR_PRIVACY_POLICY',
        to: 'https://www.scfhp.com/privacy-policy/',
        target: '_blank',
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'illustrativeCheckBadge',
        linkIcon: 'interfaceExternalLink',
      },
      {
        text: 'STR_TERMS_OF_SERVICE',
        to: 'www.scfhp.com/terms-and-conditions/',
        target: '_blank',
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'illustrativeDocument',
        linkIcon: 'interfaceExternalLink',
      },
      {
        text: 'STR_DELETE_YOUR_ACCOUNT',
        onClick: handleDeleteAccount,
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        // icon: 'interfaceDelete',
        linkIcon: 'interfaceChevronRight',
      },
    ],
  },
  {
    items: [
      {
        text: 'STR_LOG_OUT',
        to: '/sign-out',
        color: '$onSurfaceTextCritical',
        linkProps: boldTextCriticalLink,
        linkIcon: 'interfaceChevronRight',
      },
    ],
  },
];
