import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import {
  Options,
  useUserDropdownConfig,
} from 'hooks/use-user-dropdown-config.hook';

function filterUserDropdownConfig(
  config: Options[],
  isMobile: boolean,
): Options[] {
  if (isMobile) return config;

  return config
    .map((section) => ({
      ...section,
      items: section.items.filter((item) =>
        [
          'STR_PERSONAL_INFO',
          'STR_MANAGE_ACCOUNT',
          'STR_FORMS',
          'STR_DELETE_YOUR_ACCOUNT',
          'STR_COMMUNICATION',
          'STR_TERMS_OF_SERVICE',
          'STR_ABOUT_SANTA_CLARA',
          'STR_CONTACT',
          'STR_PRIVACY_POLICY',
          'STR_LOG_OUT',
        ].includes(item.text),
      ),
    }))
    .filter((section) => section.items.length > 0);
}

export const useUserDropdownComponent = (handleDeleteAccount: () => void) => {
  const { data, isLoading, error } = usePreferredFirstName();
  const userDropdownConfig = useUserDropdownConfig(handleDeleteAccount);
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  const filteredConfig = filterUserDropdownConfig(userDropdownConfig, isMobile);

  const dropdownMenuItemProps = {
    preferredFirstName: data.preferredFirstName,
    menuItemsConfig: filteredConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
