export const TENANT_ID = 'scfhp';

export const MASONRY = {
  API_VERSION: 2,
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
  MODULE_KEY_CLAIMS: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
  CUSTOM_WIDGET_TYPE_ACTIVITY: 'activity_stack',
};
export const CAMPAIGN_ID = '';
export const ACTIVITY_ID = '';

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scfhp-web',
  NO_INFO_AVAILABLE: 'no info available',
};

export const COMMUNICATION_OPTIONS = {
  SMS: 'sms',
  EMAIL: 'email',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
};

export const PATHS = {
  COMMUNICATION_PREFERENCES: '/settings/communication-preferences',
  ABOUT: '/settings/about',
  TERMS_OF_USE_ROUTE: '/',
  CONTACT_US: '/settings/contact-us',
  FORMS: '/forms',
};

export const REST_ENDPOINTS = {
  USER: '/v1/users',
};
