import * as React from 'react';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { AppNavBarComponent } from 'components/header-nav/Nav';
import { FooterComponent } from './footer/footer.component';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => (
  <Box
    css={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      overflowX: 'hidden',
      ...css,
    }}
  >
    {isPrivatePage && (
      <Box css={{ flexShrink: 0 }}>
        <AppNavBarComponent />
      </Box>
    )}
    <Box
      as="main"
      css={{
        flex: '1 0 auto',
        position: 'relative',
        overflowX: 'hidden',
        ...css,
      }}
    >
      {children}
    </Box>
    {isPrivatePage && (
      <Box css={{ flexShrink: 0 }}>
        <FooterComponent />
      </Box>
    )}
  </Box>
);
